@font-face {
  font-family: gravity-bold;
  src: url(./fonts/Gravity-Bold.otf);
}

@font-face {
  font-family: gravity-light;
  src: url(./fonts/Gravity-Light.otf);
}
@font-face {
  font-family: gravity-regular;
  src: url(./fonts/Gravity-Regular.otf);
}
.HeaderMenuResponsive{
  display: none;
  z-index: 1;
}

.HeaderMenuWeb {
  display: initial;
}
@media screen and (max-width: 450px) 
{
  .whatsapp-link{
    position: fixed;
    bottom: 3vw;
    right: 3vw;
    z-index: 10;
    padding: 2vw;
    background: white;
    border-radius: 15px;
    img{
      width: 10vw;
      height: 10vw;
    }
  }
  
  .HeaderMenu {
    font-size: 2vw;
  }
  .HeaderMenuResponsive{
    display: initial;
    z-index: 1;
  }

  .HeaderMenuWeb {
    display: none;
  }

  .wsp-logo {
    height: 4vw;
  }

  .App-logo {
    height: 10vw;
  }

  .standsOutContainer {
    margin-top: 164vw;
    margin-left: 15vw;
    line-height: 4.3vw;
  }

  .standsOutTitle {
    font-size: 6vw;
  }

  .standsOutBody {
    font-size: 3.4vw;
    width: 60vw;
  }

  .standsOutButtonLabel {
    font-size: 2.5vw;
  }
  .standsOutButtonLabelBold {
    font-size: 2.5vw;
  }
  .stands-out-mobile {
    display: initial;
    margin-top: 130vw;
    width: 100%;
    margin-left: 0;
  }

  .stands-out {
    display: none;
  }
}

@media screen and (min-width: 451px) {
  .HeaderMenuWeb {
    display: initial;
  }
  
  .stands-out-mobile {
    display: none;
  }
  .snIcon .invertedIcon {
    rotate: 180;
  }

  .HeaderMenu {
    font-size: 1.2vw;
  }

  .wsp-logo {
    height: 2.5vw;
  }

  .App-logo {
    height: 6vw;
  }
}

@media screen and (min-width: 901px) {
  .stands-out-mobile {
    display: none;
  }

  .HeaderMenuWeb {
    display: initial;
  }
  
  .stands-out-mobile {
    display: none;
  }

  .stands-out {
    margin-top: 40vw;
    width: 85vw;
    margin-left: 5vw;
  }

  .standsOutContainer {
    margin-top: 55vw;
    margin-left: 18vw;
    line-height: 2vw;
  }

  .standsOutTitle {
    font-size: 3vw;
  }

  .standsOutBody {
    font-size: 1.4vw;
    width: 28vw;
  }

  .standsOutButtonLabel {
    font-size: 1.3vw;
  }
  .standsOutButtonLabelBold {
    font-size: 1.3vw;
  }
}

@media screen and (max-width: 900px) and (min-width: 451px) {
  .HeaderMenuWeb {
    display: none;
  }

  .stands-out-mobile {
    display: none;
  }
  .stands-out-mobile {
    display: none;
  }

  .stands-out {
    margin-top: 30vw;
    width: 100%;
    margin: 40vw 0vw;
  }

  .standsOutContainer {
    margin-top: 53vw;
    margin-left: 15vw;
    line-height: 2.3vw;
  }

  .standsOutTitle {
    font-size: 4vw;
  }

  .standsOutBody {
    font-size: 2vw;
    width: 33vw;
    letter-spacing: -0.15vw;
  }
}

.App {
  display: flex;
  flex-direction: column;
}

.sky {
  z-index: -1;
  width: 100%;
  background-color: transparent;
  height: 90vw;
}
.afternoon {
  content: url(../src/images/Tarde.png);
  z-index: -1;
  width: 100%;
  background-color: transparent;
  height: 90vw;
}


.night {
  content: url(../src/images/Noche.png);
  z-index: -1;
  width: 100%;
  background-color: transparent;
  height: 73vw;
}


.white-layer {
  width: 100%;
}

.stands-out-mobile {
  left: 0;
  right: 0;
}

.stands-out {
  left: 0;
  right: 0;
}

.buildings {
  z-index: 5;
  width: 100%;
}

.characters {
  position: absolute;
  width: 13%;
  z-index: 10;
  margin: 16vw 0vw 0vw 53vw;
}

.CubeGif {
  content: url('./../src/images/cube.gif');
  position: absolute;
  width: 15%;
  z-index: 10;
  margin: 15vw 0vw 0vw 20vw;
}

.Personajes {
  content: url('./../src/images/personajes.png');
  position: absolute;
  width: 100%;
  z-index: 10;
  margin: 34.5vw 0vw 0vw 0vw;
}
.App-logo {
 cursor: pointer;
}

.HeaderMenu {
  font-family: gravity-bold;
  float: right;
  display: flex;
  list-style-type: none;
  color: #2e3540;
  margin-right: 1vw;
  align-items: center;
  padding-inline-start: 0;
  margin-inline-end: 3.5vw;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdc040;
  padding: 5px;
  z-index: 20;
  position: fixed;
  width: 100%;
}

li {
  padding-right: 2.3vw;
}

.headerTitleContainer {
  display: flex;
  line-height: 1.5vw;
  position: absolute;
  top: 35vw;
  left: 0;
  flex-direction: column;
  text-align: center;
  right: 0;
}

.headerTitle1 {
  color: #e26934;
  font-size: 3.1vw;
  font-family: gravity-bold;
  margin: 1.5vw;
}

.headerTitle2 {
  color: #2e3540;
  font-size: 4.5vw;
  font-family: gravity-bold;
  margin: 1.5vw;
}

.standsOutContainer {
  position: absolute;
  color: white;
}

.standsOutTitle {
  font-family: gravity-bold;
}

.standsOutBody {
  font-family: gravity-light;
  text-align: justify;
  word-spacing: -0.14vw;
  margin-top: -1vw;
}
.standsOutButton {
  background-color: #2e3540;
  display: flex;
  padding: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  width: fit-content;
  border-radius: 3vw;
  align-items: center;
  cursor: pointer;
  color: white;
}

.standsOutButton:hover {
  background-color: darkgrey;
  color: #2e3540;
}

.standsOutButton:hover .arrow-right {
  border-left: 1vw solid #2e3540;
}

.standsOutButtonLabel {
  font-family: gravity-light;
  margin: 0;
}

.standsOutButtonLabelBold {
  font-family: gravity-bold;
  margin: 0;
}

.arrow-right {
  width: 0;
  height: 0;
  margin-left: 1vw;
  border-top: 0.5vw solid transparent;
  border-bottom: 0.5vw solid transparent;
  border-left: 1vw solid #fdc040;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
  cursor: pointer;
}

.socialContent {
  width: 100%;
}

.displayNone {
  display: none;
}