@media screen and (max-width: 451px) {
    .termsHere{
        padding-top: 25vw;
    }    
}
@media screen and (min-width: 901px) {
    .termsHere{
        padding-top: 10vw;
    }    
}
@media screen and (max-width: 900px) and (min-width: 450px){
    .termsHere{
        padding-top: 10vw;
    }    
}
.termsComp{   
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: url(../../images/Exclusion.png);
    background-repeat: no-repeat;
    background-size: 90% auto;
    margin: 6vw 0vw;
    padding-left: 11vw;
    padding-right: 0vw;
    padding-bottom: 0vw;
    padding-top: 0vw 
  
}
.upIconn {
    content: url(./../../images/goUp.png);
    height: 3vw;
    width: auto;
    position: absolute;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
    margin: 2vw 8.3vw -105.8vw 0vw;
    right: 0;
  }
.terms{
    display: flex;
    margin: 39vw 3.8vw;
    flex-direction: column;
}


.termsTitle{
    font-size: 4vw;
    display: flex;
    color: #e26934;
    margin: 20vw auto auto 37.8vw;
    font-family: gravity-bold;
}

.termsTitle2{
    font-size: 4vw;
    color: #e26934;
    margin: auto auto auto 39.5vw;
    font-family: gravity-bold;
    width: 25vw;
}

.line{
    font-size: 3.7vw;
    color: #FDC040;
    margin: 4vw auto auto 3vw;
    font-family: gravity-bold;
    letter-spacing: -1vw;
}
.subtitle{
    font-size: 1.5vw;
    color: #050505;
    margin: 2vw 0vw 2vw 3.2vw;
    width: 70vw;
    font-family: gravity-bold;
}


.paragraph{
    font-size: 1.4vw;
    color: #050505;
    margin: 1vw 3.2vw;
    width: 70vw;
    font-family: gravity-regular;
}



.fondo{
    width:100%; 
    margin-top: -2vw;
    margin-bottom: 0vw;
    
} 

.cross1{
    width: 2.5%;
    margin-top: 330vw;
    margin-left: 3vw;
    position: absolute;
} 

.cross2{
    width:2.5%; 
    margin-top: 41vw;
    margin-bottom: 15vw;
    margin-left: 33vw;
    position: absolute;
} 

.cross3{
    width:2.5%; 
    margin-top: 96vw;
    margin-left: 3vw;
    position: absolute;
} 

.circle1{
    width:2%; 
    margin-top: 80vw;
    margin-left: 1vw;
    position: absolute;
} 


.circle2{
    width:2%; 
    margin-top: 159vw;
    margin-left: 1vw;
    position: absolute;
} 

.black1{
    width:8.5%; 
    margin-top: 42vw;
    margin-left: 0vw;
    position: absolute;
} 

.black2{
    width:8.5%; 
    margin-top: 243vw;
    margin-left: -2.2vw;
    position: absolute;
} 

.line1{
    width:8.5%; 
    margin-top: 30vw;
    margin-left: 23vw;
    position: absolute;
} 

.line2{
    width:8.5%; 
    margin-top: 50vw;
    margin-left: 75vw;
    position: absolute;
} 

.line3{
    width:8.5%; 
    margin-top: 75vw;
    margin-left: 79vw;
    position: absolute;
} 

.line4{
    width:8.5%; 
    margin-top: 374vw;
    margin-left: 9vw;
    position: absolute;
} 

.goDown{
    width:4.5%; 
    margin-top: 12vw;
    margin-left: 11vw;
    position: absolute;
} 

.goUp{
    width:2.5%; 
    margin-top: 173vw;
    margin-left: 89vw;
    position: absolute;
    display: none;
} 

.x1{
    width:2.9%; 
    margin-top: 13vw;
    margin-left: 88vw;
    position: absolute;
} 

.x2{
    width:2.9%; 
    margin-top: 60vw;
    margin-left: 89vw;
    position: absolute;
} 

.orange1{
    width: 5.5%;
    margin-top: 119vw;
    margin-left: 85vw;
    position: absolute;
} 

.orange2{
    width: 5.5%;
    margin-top: 268vw;
    margin-left: 86vw;
    position: absolute;
} 


